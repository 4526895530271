import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LeadingSection from "@components/leadingSection";
import ValuePropositionSection from "@components/valuePropositionSection";
import FeaturesSection from "@components/featuresSection";
import TestimonialsSection from "@components/testimonialsSection";

import "@styles/styles.scss";

const IndexPage = ({ data }) => {
	const ctf = data.contentfulLandingPage;
	const {
		headline,
		subHeadline,
		valuePropositionHeadline,
		recipientsOfBenefits,
		featuresHeadline,
		features,
		testimonialsHeadline,
		testimonials
	} = ctf;
	//const testimonialsSubtitle = ctf.testimonialsSubtitle.testimonialsSubtitle;
  const assets = data.allContentfulAsset.edges.map(edge => edge.node);

	return (
		<Layout>
			<SEO title="Home" />
			<LeadingSection
				headline={headline}
				subHeadline={subHeadline}
        assets={assets}
			/>
			<ValuePropositionSection
				valuePropositionHeadline={valuePropositionHeadline}
				recipientsOfBenefits={recipientsOfBenefits}
        assets={assets}
			/>
			<FeaturesSection
				featuresHeadline={featuresHeadline}
				features={features}
        assets={assets}
			/>
      <TestimonialsSection
        testimonialsHeadline={testimonialsHeadline}
        testimonials={testimonials}
        assets={assets}
      />
      <div className="container">
      </div>
		</Layout>
	);
};

export default IndexPage;

export const pageQuery = graphql`
	query LandingQuery {
		contentfulLandingPage {
			headline
			subHeadline
			valuePropositionHeadline
			recipientsOfBenefits {
				recipientContent
				recipientTitle
        assetTitle
        learnMoreLink
			}
			featuresHeadline
			features {
				featureTitle
        assetTitle
				features {
					text
				}
			}
			testimonialsHeadline
			testimonialsSubtitle {
				testimonialsSubtitle
			}
			testimonials {
				testimonialTitle
        assetTitle
				testimonialContent
			}
		}
    allContentfulAsset {
      edges {
        node {
          title
          file {
            contentType
            url
            fileName
          }
          fixed {
            height
            width
            src
            srcSet
          }
          fluid(maxWidth: 980) {
            ...GatsbyContentfulFluid
          } 
        }
      }
    }
	}
`;
