import React from 'react';

import Banner from '@components/banner';
import { EnquireUrl } from '@constants/app';

export default ({ headline, subHeadline, assets }) => {
	return (
    <Banner
      assets={assets}
      imageTitle="homepageBanner"
    >
      <div className="d-flex flex-column mt-3 mb-5 mb-md-0">
        <h1 className="h3 font-semibold">{headline}</h1>
        <h2 className="h6 text-dark pt-3 font-medium">{subHeadline}</h2>
        <div>
          <a
            href={EnquireUrl}
            className="btn btn-sm btn-primary mt-4"
          >Enquire</a>
        </div>
      </div>
    </Banner>
	);
};
