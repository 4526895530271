import React from 'react';
import { Link } from 'gatsby';

import ProductLogos from '@components/productLogos';

export default ({
	valuePropositionHeadline,
	recipientsOfBenefits,
  assets
}) => {

  const getAsset = (assetTitle) => assets.filter((asset) => asset.title === assetTitle)[0].file.url;

	return (
    <section className="my-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="flex-center flex-column">
              <h2 className="text-center font-semibold">{valuePropositionHeadline}</h2>
              <Link to={"/discover"} className="mt-3 font-semibold">Learn how this will save you money ></Link>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100">
        <ProductLogos/>
      </div>
      <div className="px-4">
        <div className="row mt-5">
          { recipientsOfBenefits.map((recipient, i) => (
            <div key={i} className="col-lg-3">
              <div className="card border-0">
                <div 
                  className="flex-center"
                >
                  <img 
                    className="card-img-top" 
                    src={getAsset(recipient.assetTitle)} 
                    alt="graphic decoration" 
                    style={{ maxWidth: '340px' }}
                  />
                </div>
                <div className="card-body pb-0">
                  <h5 className="card-title font-semibold">{recipient.recipientTitle}</h5>
                  <p className="font-medium">{recipient.recipientContent}</p>
                  <Link
                    to={recipient.learnMoreLink}
                    className="btn btn-primary btn-sm"
                  >
                    Find the right product for you
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
		</section>
	);
};

