import React from 'react';

import RoundedSquareTick from '@images/roundedSquareTick.svg';

export default ({
	featuresHeadline,
	features,
  assets
}) => {

  const getAsset = (title) => assets.filter(asset => asset.title === title)[0];

	return (
    <section className="w-100 bg-light">
      <div className="container flex-center flex-column pt-5 pb-5">
        <div className="row">
          <div className="col-12">
            <div className="flex-center">
              <h2>{featuresHeadline}</h2>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          { features.map((feature, i) => (
            <div key={i} className="col-md-4 mt-3">
              <div className="card h-100 border-0">
                <div className="py-4">
                  <img 
                    className="card-img-top" 
                    src={getAsset(feature.assetTitle).file.url}
                    alt="feature"
                    style={{ maxHeight: '146px' }}
                  />
                </div>
                <div className="card-body">
                  <h5 className="card-title font-semibold">{feature.featureTitle}</h5>
                  <ul className="list-unstyled m-0 p-0">
                    {feature.features.map((item, j) => (
                      <li key={j} className="card-text mt-2">
                        <RoundedSquareTick fill="#3795E2" width="20" height="20"/>
                        &nbsp;<span className="ml-2 font-medium">{item.text}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
	);
};
